.featured-tile {
  background-color: $purple-dark;
  height: 760px;
  padding-top: 70px;
  background-size: cover;
  background-position: center center;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  .gradient-tile {
    z-index: 1;
  }
  @media screen and (max-width: 480px) {
    height: 640px;
  }
}

.container-featured-tile {
  position: relative;
  z-index: 5;
}

.gradient-tile {
  position: absolute;
  height: 180px;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(transparent, $purple-900);
  width: 100%;
}

.gradient-trailer {
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(transparent, $black);
  width: 100%;
}

.btn-blur {
  color: #fff;
  backdrop-filter: blur(20px);
  background-color: transparent;
  border: transparent;
  &:hover {
    background-color: #fff !important;
    color: #000 !important;
  }
  &:focus {
    background-color: transparent !important;
    color: #fff !important;
    box-shadow: none;
  }
}

.btn-featured-tile {
  padding-left: 2rem;
  padding-right: 2rem;
  @media screen and (max-width:480px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.featured-tile-title {
  @media screen and (max-width: 575px) {
    font-size: 3.125rem;
  }
}

.featured-tile-description {
  font-size: 1.125rem;
  @media screen and (max-width: 575px) {
    font-size: 0.875rem;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
}