.login-button {
  background-color: $white;
  border-radius: $border-radius;
  border: 1px solid $white;
  padding: 1rem 1rem;
  font-weight: 500;
  font-size: 1rem;
}

.logo-provider {
  width: 15%;
  aspect-ratio: 3/2;
  object-fit: contain;
  height: 24px;
  &.custom {
    width: auto;
  }
  &.cla_cl {
    width: 50px;
    position: relative;
    top: -3px;
  }
}
