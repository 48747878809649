.avatar-profile {
  width: 185px;
  height: 185px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  background-color: $purple;
}

.bg-profile-head {
  background-color: $purple-dark;
}
