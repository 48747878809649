.bg-gradient-navbar {
  background-image: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.00) 100%);
}

.navbar {
  transition: .3s ease-in-out;
  width: 100%;
}

.bg-navbar-custom {
  background-color: rgba(9, 1, 16, 0.98) !important;
}

.avatar-user-img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.avatar-user-no-img {
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #fff;
  background-color: $purple;
  border-radius: 50%;
}

.sidebar-bg {
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.8);
  z-index: 1035;
  overflow: hidden;
  display: none;
  transition: .3s ease-in-out;
  opacity: 0;
  &.show {
    display: block;
    opacity: 1;
  }
  .sidebar {
    width: 100%;
    background-color: rgba($purple-dark, 0.5);
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    bottom: 0;
    left:0;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.buttons-sidebar {
  .btn {
    display: block;
    width: 50%;
    margin-bottom: 0.5rem;
  }
}

.explore-list-sidebar {
  .dropdown-item, .dropdown-header {
    padding: 0;
  }
  .dropdown-item {
    opacity: 0.6;
    margin-bottom: 0.25rem;
  }
}

@media screen and (max-width: 480px) {
  .avatar-user-img, .avatar-user-no-img {
    width: 42px;
    height: 42px;
  }
}

.hr-sidebar {
  border: 0;
  clear:both;
  display:block;
  width: 100%;               
  background-color: rgba($white, 0.5);
  height: 1px;
  margin: 0;
}

.btn-premier {
  display: flex;
  @media screen and (max-width: 1200px) {
    display: none;    
  }
}
