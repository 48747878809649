@mixin borders-bottom-rounded {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

@mixin floating-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

@mixin background-blur {
  background-color: rgba(255, 255, 255, 0.16) !important;
  backdrop-filter: blur(20px);
}