.toast {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: 400px;
  .toast-header {
    border-bottom: none;
    background-color: transparent;
    .close {
      font-family: $font-family-base;
      &:focus {
        outline: 0;
      }
    }
  }
  &.toast-warning {
    background-color: #FFC94D;
    color: $purple-dark;
    .toast-header {
      color: $purple-dark;
    }
  }
  &.toast-success {
    background-color: $success;
    color: $purple-dark;
    .toast-header {
      color: $purple-dark;
    }
  }
  &.toast-danger {
    background-color: $danger;
    color: $purple-dark;
    .toast-header {
      color: $purple-dark;
    }
  }
  &.toast-info {
    background-color: $info;
    color: $purple-dark;
    .toast-header {
      color: $purple-dark;
    }
  }
}

.link-button {
  background-color: transparent;
  border: none;
  text-decoration: none;
  font-size: 0.8rem;
  padding: 0;
}
