.recomended {
  position: absolute;
  bottom: 12rem;
  left: 3rem;
  background-color: rgba(0,0,0,0.5);
  width: 1000px;
  height: 300px;
  padding: 1rem;
  border-radius: 1rem;
  backdrop-filter: blur(20px);
  transition: .2s ease-in-out;
  overflow: hidden;
  @media screen and (max-width: 1200px) {
    width: 825px;
    bottom: 8.5rem;
    height: 250px;
    .recomended-description{
      width: 90%;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }
  }
  @media screen and (max-width: 992px) {
    width: 600px;
    bottom: 7rem;
    height: 185px;
    z-index: 5;
    .recomended-description{
      width: 95%;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }
    .title-movie {
      font-size: 25px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media screen and (max-width: 767px) {
    width: 490px;
    bottom: 4rem;
    left: 10px;
    height: 200px;
    z-index: 5;
    .recomended-description{
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }
  }
  @media screen and (max-width: 576px) {
    width: 450px;
    bottom: 6.5rem;
    left: 2rem;
    height: 190px;
    z-index: 5;
    .recomended-description{
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }
  }
  @media screen and (max-width: 480px) {
    width: 400px;
    bottom: 3.8rem;
    left: 1rem;
    height: 180px;
    z-index: 5;
    .recomended-description{
      width: 90%;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }
  }
  @media screen and (max-width: 390px) {
    width: 325px;
    bottom: 0.5rem;
    left: 1rem;
    height: auto;
    z-index: 5;
    .title-movie {
      font-size: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  p {
    font-family: $font-family-base;
  }

  &-description{
    width: 90%;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    font-size: 13px;
  }

  .btn-close {
    position: absolute;
    display: block;
    top: 0;
    right: 1px;
    font-size: 16px;
    z-index: 2;
  }
}

.recomended-cover {
  height: auto;
  width: 100%;
  border-radius: $border-radius;
  @media screen and (max-width:480px) {
    margin-bottom: 0rem;
    height: 100px;
  }
}

@keyframes slide {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
    background-color: $primary;
  }
}

.title-movie {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}