.p-episode {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.episode-cover {
  height: auto;
  object-fit: cover;
  object-position: center;
  border-radius: $border-radius;
  width: 100%;
}

.play-episode-icon {
  position: absolute;
  bottom: 1.5rem;
  left: 0.5rem;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;
  @media screen and (max-width: 992px) {
    bottom: 2rem;
  }
  &.is-paid {
    @include background-blur;
  }
  &.is-disabled {
    @include background-blur;
  }
}

.card-episode {
  transition: 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    filter: brightness(80%);
    .play-episode-icon {
      &.is-paid {
        background-color: $gold-500 !important;
        color: black;
      }
      &.is-disabled {
        background-color: $blur-gray !important;
        color: black;
      }
    }
  }
  &.is-disabled {
    cursor: default;
  }
}
