
.slider {
  display: flex;
  position: relative;
  &__container {
    display: flex;
    padding: 0;
    transition: transform 300ms ease 100ms;
    width: 100%;
    z-index: 1;
  }
  &:not(&--open) .item:hover {
    transform: scale(1.2)!important;
    transition-delay: 500ms;
    z-index: 100 !important;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50), 0 20px 40px 0 #120024;
    img {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .content-item {
      @include borders-bottom-rounded
    }
    @media screen and (max-width: 480px) {
      transform: none !important;
      box-shadow: none;
      img {
        @include borders-bottom-rounded
      }
    }
  }
  &:not(&--open) .item.item-continue-watching:hover {
    transform: none!important;
    transition-delay: 500ms;
    z-index: 100 !important;
    box-shadow: none;
    img {
      @include borders-bottom-rounded;
      border: 3px solid $purple-pink;
    }
    .content-item {
      @include borders-bottom-rounded
    }
  }
  &:not(&--open) .item.item-branded:hover {
    img {
      @include borders-bottom-rounded
    }
  }
  &:not(&--open) .item.item-tvod:hover {
    box-shadow: none;
    img {
      @include borders-bottom-rounded
    }
  }
  &:not(&--open):hover .item {
    z-index: 1;
  }
}

.slider-wrapper {
  padding: 10px 0 20px 0;
  overflow: visible;
  position: relative;
  &:hover {
    z-index: 10;
  }
  &.slider-explore {
    .slider__container {
      flex-flow: row wrap;
    }
    .slide-button {
      display: none;
    }
    .item {
      margin-bottom: 1.5rem;
      @media screen and (max-width: 1077px) {
        flex: 0 0 24%;
      }
      @media screen and (max-width: 768px) {
        flex: 0 0 48%;
      }
      @media screen and (max-width: 480px) {
        flex: 0 0 49%;
      }
      @media screen and (max-width: 418px) {
        flex: 0 0 48.5%;
      }
    }
  }
  @media screen and (max-width: 480px) {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.item {
  flex: 0 0 19.6%;
  margin: 0 2px;
  border-radius: 0.5rem;
  position: relative;
  transition: .2s ease-in-out;
  transition-delay: 0;
  &.item-continue-watching {
    img {
      transition: .3s ease-in-out;
      border: 3px solid transparent;
    }
    .btn-play {
      width: 60px;
      height: 60px;
    }
  }
  &.item-branded {
    .btn-play {
      width: 40px;
      height: 40px;
    }
  }
  @media screen and (max-width:1024px) {
    flex: 0 0 24% !important;
  }
  @media screen and (max-width:992px) {
    flex: 0 0 35% !important;
  }
  @media screen and (max-width:480px) {
    flex: 0 0 50% !important;
  }
  img {
    height: 100%;
    width: 100%;
    vertical-align: top;
    object-fit: cover;
    object-position: center;
    border-radius: 0.5rem;
  }
  .content-item {
    position: absolute;
    width: 100%;
    background-color: #fff;
    color: #000;
    z-index: 8;
    .content-title {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.slide-button {
  position: absolute;
  bottom: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4.5rem;
  height: 4.5rem;
  border: 0;
  outline: 0;
  padding: 0;
  z-index: 5;
  border-radius: 50%;
  transition: .2s ease-in-out;
  background-color: $white !important;
  span {
    transition: .2s ease-in-out;
    color: $purple-900 !important;
    font-size: 1.5rem;
  }

  &:hover {
    background-color: $white-400 !important;
    span {
      color: $purple-900;
      font-size: 1.5rem;
    }
  }

  &:focus {
    outline: 0;
  }

  span {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  &--next {
    right: .1rem;

    span {
      transform: rotateZ(-90deg);
    }
  }

  &--prev {
    left: .1rem;

    span {
      transform: rotateZ(90deg);
    }
  }
  @media screen and (max-width:480px) {
    display: none;
  }
}

.show-details-button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 300ms ease 100ms;
  background: transparent;
  border: 0;
  outline: none;
  width: 100%;

  span {
    display: block;
    width: 14px;
    margin: 0 auto;
    color: white;
  }
}

.category-name {
  padding: 0 0 0 55px;
}

.transform-style {
  transform-style: preserve-3d;
}

.container-dots {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  .dots {
    display: flex;
    .dot {
      background-color: #400091;
      height: 4px;
      width: 40px;
      margin: 0 2px;
      border-width: 0;
      transition: .3s ease-in-out;
      &.active {
        background-color: #C56EFE;
      }
      &:focus {
        outline: 0;
      }
      &:hover {
        filter: brightness(85%);
      }
    }
    .dot-branded {
      position: relative;
      background-color: #746d80;
      height: 4px;
      width: 40px;
      margin: 0 2px;
      border-width: 0;
      transition: .3s ease-in-out;
      &.active {
        background-color: #ded2e6;
      }
      &:focus {
        outline: 0;
      }
      &:hover {
        filter: brightness(85%);
      }
    }
  }
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.tile-continue-watching {
  position: relative;
  .btn-play {
    @include floating-center
  }
}

.tile-continue-watching .image-container {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
}

.tile-continue-watching .image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.normal-image-container {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.text-resume {
  z-index: 8;
}

.btn-info-tile {
  position: absolute;
  top: .25rem;
  right: .5rem;
  z-index: 10;
}

.container-overflow {
  overflow-x: hidden;
  @media screen and (max-width:480px) {
    overflow-x: auto;
  }
}

.container-category {
  @media screen and (max-width: 480px) {
    padding-right: 0;
  }
}

.category-container-title {
  display: flex;
  align-items: center;
  @media screen and (max-width:480px) {
    justify-content: space-between;
    padding-right: 1.5rem;
  }
}

.branded-container {
  display: flex;
  justify-content: space-between;
  margin-right: 3rem;
  @media screen and (max-width:992px) {
    display: block;
  }
}

.branded-banner {
  width: 10rem;
  height: 100%;
  margin-right: 2rem;
  height: 100%;
  @media screen and (max-width:992px) {
    display: none;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.branded-container-info {
  display: block;
  align-self: center;
  margin-right: 2rem;
  @media screen and (max-width:992px) {
    margin-right: 0;
    width: 100%;
  }
}

.branded-title {
  display: block;
  align-items: center;
  width: 15rem;
  @media screen and (max-width:992px) {
    text-align: center;
    padding-top: 1rem;
    width: 50%;
    margin: 0px auto;
  }
  h4{
    font-size: 1.7rem;
    @media screen and (max-width:992px) {
      font-size: 1.4rem;
    }
  }
  p{
    font-size: 1rem;
    @media screen and (max-width:992px) {
      font-size: 0.8rem;
    }
  }
}

.branded-logo-container {
  display: block;
  align-items: center;
  @media screen and (max-width:992px) {
    display: flex;
    text-align: center;
    justify-content: space-around;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

.branded-logo {
  width: auto;
  height: 2rem;
  margin-right: 1rem;
  @media screen and (max-width:768px) {
    width: 7rem;
    height: auto;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.btn-branded {
  font-size: 0.9rem;
  padding: 1rem 0.5rem;
  @media screen and (max-width:992px) {
    font-size: 0.7rem;
    padding: auto;
  }
}

.item-branded {
  flex: 0 0 14.5%;
  position: relative;
  align-self: center;
  .btn-play {
    @include floating-center
  }
}

.item-tvod {
  flex: 0 0 14.5%;
  position: relative;
  align-self: center;
  height: 100%;
}

.container-profile {
  display: flex;
  flex-direction: row;
  gap: 20px;
  @media (max-width: 768px) { 
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}

.ticket-badge-container {
  position: relative;
}

.badge-wrapper {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  z-index: 2;
}

.ticket-badge {
  font-size: 0.9rem;
}
@media screen and (max-width: 1200px) {
  .ticket-badge {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 600px) {
  .ticket-badge {
    font-size: 0.6rem;
  }
}

.branded-carousel {
  padding-top: 1.2rem;
}

.tvod-carousel {
  padding-top: 1.2rem;
  .slider {
    .content {
      flex: 0 0 26%;
      align-self: center;
      .container-content {
        width: 85%;
        h1 {
          line-height: 3rem;
        }
      }
    }
    .item {
      border: 3px solid transparent !important;
      border-radius: 0.5rem;
      flex: 0 0 33%;
      &.item-tvod-flex-h {
        flex: 0 0 99.6% !important;
        @media screen and (min-width: 1921px) {
          flex: 0 0 75% !important;
        }
      }
      &.item-tvod-flex-v {
        flex: 0 0 33% !important;
      }
      transition: .2s ease-in-out;
      transition-delay: 0;
      .bg-gradient-darks {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.05) 40%, rgba(0,0,0,0.5) 100%);
        border-radius: 0.5rem;
      }
      .btn-play {
        @include floating-center;
        z-index: 2;
      }
      .tvod-info-content {
        position: absolute;
        bottom: 2%;
        left: 2%;
        z-index: 2;
      }
      .tvod-item-content {
        position: absolute;
        bottom: 2%;
        right: 2%;
        display: flex;
        align-items: center;
        z-index: 2;
      }
      .tvod-badge {
        position: absolute;
        top: 2%;
        right: 2%;
        font-size: 1rem;
        z-index: 2;
      }
      .btn-blur {
        @include background-blur
      }
      .btn-more-info {
        border-radius: 1rem;
        &:focus {
          box-shadow: $gold-500 !important;
        }
        &:hover {
          background: #FAF7FC !important;
          color: #0E021A !important;
          box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.24);
          border-radius: 32px;
        }
      }
      &:hover {
        border: 3px solid $gold-400 !important;
        transition-delay: 0;
        .tvod-badge {
          @include background-blur;
          transition-delay: 500ms;
          color: $white;
        }
      }
    }
    &:not(&--open) .item:hover {
      transform: none !important;
      img {
        @include borders-bottom-rounded
      }
    }
    @media screen and (max-width: 480px) {
      .item-branded-flex-v {
        flex: 0 0 55% !important;
      }
      .item {
        border: 0 !important;
      }
    }
  }
}

.item-branded-flex-h {
  flex: 0 0 25%;
  @media screen and (max-width:1200px) {
    flex: 0 0 30%;
  }
  @media screen and (max-width:992px) {
    flex: 0 0 33%;
  }
  @media screen and (max-width:768px) {
    flex: 0 0 35%;
  }
  @media screen and (max-width:568px) {
    flex: 0 0 45%;
  }
  @media screen and (max-width:480px) {
    flex: 0 0 35%;
  }
}

.item-branded-flex-v {
  @media screen and (max-width:1400px) {
    flex: 0 0 18%;
  }
  @media screen and (max-width:1200px) {
    flex: 0 0 22%;
  }
  @media screen and (max-width:992px) {
    flex: 0 0 20%;
  }
  @media screen and (max-width:768px) {
    flex: 0 0 18.5%;
  }
  @media screen and (max-width:568px) {
    flex: 0 0 44%;
  }
  @media screen and (max-width:480px) {
    flex: 0 0 45%;
  }
}

.btn-branded-info {
  border-radius: 50%;
  position: absolute;
  width: 2rem;
  height: 2rem;
  right: 2%;
  bottom: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.fallbackImage {
  position: relative;
}

.fallbackContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fallbackText {
  position: absolute;
  bottom: 40%;
  font-size: 1.2rem;
  font-family: $font-family-secondary;
  text-align: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  @media screen and (max-width:1200px) {
    font-size: 1.1rem;
  }
  @media screen and (max-width:480px) {
    font-size: 1rem;
  }
  @media screen and (max-width:320px) {
    font-size: 0.8rem;
  }
}

#player-tvod-container {
  background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.05) 40%, rgba(0,0,0,0.5) 100%);
  border-radius: 0.5rem;
  width: 75%;
  height: 100%;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.tvod-slider-carousel {
  padding: auto;
  @media screen and (max-width: 480px) {
    padding: 0 !important;
  }
}

.slider-tvod {
  overflow-x: hidden;
  margin: 0 auto;
  &.landscape {
    width: 80% !important;
  }
  &.portrait {
    width: 90% !important;
  }
}

.player-tvod {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 550px;
  padding: 0 1rem;
  @media screen and (max-width: 1400px) {
    height: 450px;
  }
  @media screen and (max-width: 1200px) {
    height: 350px;
  }
  @media screen and (max-width: 768px) {
    height: 250px !important;
  }
  @media screen and (max-width: 480px) {
    height: 200px;
  }
}

.container-slider-tvod {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

.skeleton-info-tvod {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-left: 1rem;
  @media screen and (max-width: 768px) {
    align-items: center;
  }
}

.skeleton-container-tvod {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skeleton-player-tvod {
  width: 50rem !important;
  height: 27rem !important;
  @media screen and (max-width: 1200px) {
    width: 35rem !important;
    height: 23rem !important;
  }
  @media screen and (max-width: 992px) {
    width: 27rem !important;
    height: 17rem !important;    
  }
}
