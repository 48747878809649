.progress-bar-custom {
  height: 8px;
  flex-grow: 1;
  background-color: #400091;
  &.progress-bar-paid {
    background-color: rgba(255,255,255, 0.5);
    .progress-bar {
      background: linear-gradient(90deg, #F3A641 0%, #F3CC41 100%)
    }
  }
  .progress-bar {
    border-radius: 0.25rem;
    background: linear-gradient(90deg, #7F00FE 0%, #FF80A3 100%)
  }
}
