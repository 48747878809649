.tabs-custom {
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 0;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  .nav-link {
    text-align: center;
    color: $purple-pastel;
    &:hover {
      border: 1px solid transparent;
    }
    &.active {
      color: $purple-pink;
      background-color: transparent;
      border-color: transparent;
      border-bottom: 4px solid $purple-pink;
    }
  }
  &.tabs-paid {
    .nav-link {
      color: $white;
      &.active {
        color: $white;
        border-bottom: 4px solid $white;
      }
    }
  }
  @media (max-width: 480px) {
    font-size: 0.65rem;
  }
}
