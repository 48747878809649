.fav-button {
  border: 0;
  background-color: transparent;
  transition: 0.2s all;
  &:focus {
    outline: 0;
  }
  &:active {
    animation-name: grow;
    animation-duration: 1000ms;
    animation-iteration-count: 1;
    animation-timing-function: linear;
  }
  &.active {
    .far, .fal {
      font-weight: 600;
      color: $success;
    }
  }
  &.fav-button-featured {
    color: #fff;
    backdrop-filter: blur(20px);
    &:hover {
      background-color: #fff;
      color: #000;
    }
  }
}

@keyframes grow {
  0% {
      transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
      transform: scale(1);
  }
}