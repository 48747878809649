.next-episode {
  position: absolute;
  bottom: 12rem;
  left: 3rem;
  background-color: rgba(0,0,0,0.5);
  width: 1000px;
  height: 300px;
  border-radius: 1rem;
  backdrop-filter: blur(20px);
  padding: 1rem;
  transition: .2s ease-in-out;
  overflow: hidden;
  @media screen and (max-width: 1200px) {
    width: 825px;
    bottom: 8.5rem;
    height: 250px;
  }
  @media screen and (max-width: 992px) {
    width: 600px;
    bottom: 7rem;
    height: 185px;
    .next-episode-description{
     font-size: 13px;
     display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .episode-duration{
      display: none;
    }
    .title-episode {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 15px;
    }
  }
  @media screen and (max-width: 767px) {
    width: 480px;
    bottom: 4rem;
    left: 1rem;
    height: 200px;
    z-index: 5;
    .next-episode-description{
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .title-episode {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 15px;
    }
    .episode-duration{
      font-size: 12px;
    }
  }
  @media screen and (max-width: 574px) {
    width: 420px;
    bottom: 4rem;
    left: 1rem;
    height: 190px;
    z-index: 5;
    .episode-duration{
      font-size: 12px;
    }
  }
  @media screen and (max-width: 480px) {
    width: 300px;
    bottom: 0.5rem;
    left: 1rem;
    height: 180px;
    z-index: 5;
  }
  @media screen and (max-width: 390px) {
    width: 325px;
    bottom: 0.5rem;
    left: 1rem;
    height: auto;
    z-index: 5;
  }
  &:hover {
    filter: brightness(0.9);
    background-color: rgba(0,0,0,0.6);
  }
  p {
    font-family: $font-family-base;
  }
  &-description{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .play-icon {
    top: 50%;
    left: 50%;
    position: absolute;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%,-50%);
    font-size: 2rem;
    &:hover {
      background-color: rgba(0,0,0,0.7);
    }
    @media screen and (max-width: 480px)
    {
      height: 80px;
      width: 80px;
      font-size: 1.5rem;
      top: 70%;
      transform: translate(-50%,-70%);
    }
  }
}

.btn-loading {
  background-color: lighten($primary, 40%);
  position:relative;
  border: 0;
  overflow: hidden;
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: $primary;
    animation-name: slide;
    animation-duration: 10s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  span {
    position: relative;
    z-index: 2;
  }
  @media screen and (max-width: 992px){
    position: absolute;
    left: 9rem;
    right: 10px;
    font-size: 12px;
    bottom: 0.1px;
    padding-top: 1px;
    padding-bottom: 1px;
  }
  @media screen and (max-width: 767px){
    position: absolute;
    top: 0;
    font-size: 8px;
  }
}

@keyframes slide {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
    background-color: $primary;
  }
}

.title-episode {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
