$avod-color1: #722AF3;
$avod-color2: #EF87A3;
$avod-color3: rgba(239, 135, 163, 0.24);
$avod-color4: #7F00FE;
$avod-color5: #C56EFE;

$tvod-color1: #F3A641;
$tvod-color2: #F3CC41;
$tvod-color3: rgba(252, 205, 80, 0.24);
$tvod-color4: #FBBF1E;
$tvod-color5: #FFC130;

@mixin player-styles($color1, $color2, $color3, $color4, $color5, $imageUrl) {
    .bmpui-ui-uicontainer .bmpui-ui-subtitle-overlay .bmpui-ui-subtitle-label {
        font-size: 1.5rem;
        padding-bottom: 1.5rem;
    }
    
    .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition {
        background: linear-gradient(90deg, $color1 0%, $color2 100%);
        margin: auto 0;
        transition: .1s linear,.1s linear;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform,-webkit-transform;
    }
    
    .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition-marker, 
    .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition-marker {
        background-color: $color3;
        border-color: $color2;
    }
    
    .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-bufferlevel, 
    .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-bufferlevel {
        background: rgba(255, 255, 255, 0.48);
    }
    
    .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition,
    .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition-marker {
        background-color: $color5;
    }
    
    .bmpui-ui-fullscreentogglebutton.bmpui-on {
        background-image: url($imageUrl);
    }
    
    .bmpui-ui-fullscreentogglebutton {
        background-image: url(../images/fullscreen-off.svg);
    }
    
    .bmpui-ui-volumetogglebutton.bmpui-muted, 
    .bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="0"] {
        background-image: url(../images/muted.svg);
    }
    
    .bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="1"], 
    .bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="2"], 
    .bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="3"],
    .bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="4"], 
    .bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="5"],
    .bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="6"], 
    .bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="7"], 
    .bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="8"], 
    .bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="9"], 
    .bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="10"] {
        background-image: url(../images/volume-on.svg);
    }
    
    .bmpui-ui-playbacktogglebutton {
        background-image: url(../images/play.svg);
    }
    
    .bmpui-ui-playbacktogglebutton.bmpui-on {
        background-image: url(../images/pause.svg);
    }
    
    .bmpui-ui-hugeplaybacktogglebutton .bmpui-image {
        background-image: url(../images/play-overlay.svg);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 7em;
        height: 100%;
        width: 100%;
    }
    
    .bmpui-ui-settingstogglebutton,
    .bmpui-ui-settingstogglebutton.bmpui-on {
        background-image: url(../images/subtitles-on.svg);
    }
    
    .custom-subtitles-button {
        background-image: url(../images/subtitles-on.svg);
        position: absolute;
    }
    
    .bmpui-ui-buffering-overlay {
        background-color: $color3;
    }
    
    .bmpui-ui-buffering-overlay .bmpui-ui-buffering-overlay-indicator {
        background-image: url(../images/riivi-loader.svg); 
    }
    
    .bmpui-ui-selectbox,
    .bmpui-ui-selectbox option:checked {
        background-color: transparent;
        border: 0;
        color: $color5;
        cursor: pointer;
        font-size: .8em;
        padding: 0.3em;
    }
    
    .bmpui-ui-settings-panel-page .bmpui-container-wrapper>.bmpui-ui-label,
    .bmpui-ui-settings-panel-page .bmpui-container-wrapper>.bmpui-ui-selectbox {
        font-size: 1.3rem;
    }
    
    .bmpui-ui-listbox .bmpui-ui-listbox-button.bmpui-selected {
        background-color: $color5;
    }
    
    .bmpui-ui-skin-smallscreen .bmpui-ui-settings-panel {
        z-index: 1;
    }
}

#player-avod {
    @include player-styles($avod-color1, $avod-color2, $avod-color3, $avod-color4, $avod-color5, '../images/fullscreen-on.svg');
}

#player-tvod {
    @include player-styles($tvod-color1, $tvod-color2, $tvod-color3, $tvod-color4, $tvod-color5, '../images/trailer-fullscreen-on.svg');
}