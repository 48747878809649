.search-box {
  width: 350px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: .25rem;
  .form-control {
    backdrop-filter: blur(20px);
    background-color:rgba(255,255,255, 0.2);
    border-color:rgba(255,255,255, 0.2);
    color: $white;
    border-radius: 5rem;
    padding: .375rem .75rem .375rem 2.5rem;
    font-size: 0.875rem;
    text-overflow: ellipsis;
    &::placeholder {
      color: rgba(255, 255, 255, 0.8);
      text-align: center;
    }
    &:focus {
      &::placeholder {
        color: transparent;
      }
      box-shadow: 0 0 0 0.15rem rgba(255, 255, 255, 0.5);
    }
  }
  .fa {
    position: absolute;
    top: .65rem;
    left: 1rem;
    color: rgba(255, 255, 255, 0.8);
  }
  @media screen and (max-width:768px) {
    width: 200px;
  }
}


