@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap');

$primary: #7f00fe !default;
$success: #8CFAC7 !default;
$warning: #FFE59E !default;
$info: #94CBFF !default;
$primary-light: #d3a8ff !default;
$danger: #FFA8B4 !default;
$pink-light: #ff80bd !default;
$purple: #1e003b !default;
$purple-dark: #120024 !default;
$purple-light: #7f00fe !default;
$purple-pink: #C56EFE !default;
$purple-pastel: #A18FB3 !default;
$purple-400: #EBE4F2 !default;
$purple-800: #1A0c27 !default;
$purple-900: #0D0218 !default;
$green-light: #69D6C4 !default;
$body-color: #fff !default;
$white-200: #FAF7FC !default;
$white-300: #F2EDF7 !default;
$white-400: #EBE4F2 !default;
$orange: #F47724 !default;
$gold-500: #FFA500 !default;
$gold-400: #FFB733 !default;
$white: $body-color;
$black: #000 !default;
$light-gray: #F2EDF7;
$blur-gray: rgba(255, 255, 255, 0.16) !default;
$pink-fluorescent: #FF3D71 !default;


$theme-colors: (
  'primary': $primary,
  'primary-light': $primary-light,
  'danger': $danger,
  'pink-light': $pink-light,
  'purple': $purple,
  'purple-dark': $purple-dark,
  'purple-light': $purple-light,
  'purple-pastel': $purple-pastel,
  'purple-pink': $purple-pink,
  'purple-400': $purple-400,
  'purple-800': $purple-800,
  'purple-900': $purple-900,
  'green-light': $green-light,
  'white': $white,
  'white-200': $white-200,
  'white-300': $white-300,
  'white-400': $white-400,
  'orange': $orange,
  'gold-500': $gold-500,
  'gold-400': $gold-400,
  'black': $black,
  'light-gray': $light-gray,
  'blur-gray': $blur-gray,
  'pink-fluorescent': $pink-fluorescent,
);

$font-family-base: 'DM Sans', sans-serif !default;
$font-family-secondary: 'Poppins', sans-serif !default;
$font-weight-bold: 600 !default;
$small-font-size: 0.75rem !default;

$btn-font-size: 0.875rem !default;
$btn-font-weight: $font-weight-bold;

$bg-gradient-primary: linear-gradient(-90deg, #1a003b 0%, #30006e 100%) !default;

$input-btn-font-size-lg: 1rem !default;
$input-btn-padding-y-lg: 0.875rem !default;
$btn-border-radius: 0.5rem !default;
$btn-border-radius-lg: $btn-border-radius;
$enable-responsive-font-sizes: true;

