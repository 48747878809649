.container-landing {
  overflow-x: hidden;
}

.py-custom {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.img-tv {
  width: 85vw;
}

.bg-ct-first {
  position: relative;
  padding-top: 10rem;
  margin-top: -40rem;
  z-index: 5;
  @media screen and (max-width: 480px) {
    margin-top: -25rem;
  }
}

.container-cover {
  display: flex;
  flex-flow: row nowrap;
  .img-cover {
    width: 22%;
    margin: 6px;
  }
}

.custom-shape-divider-bottom-1607088732 {
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1607088732 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 200px;
  @media screen and (max-width: 480px) {
    height: 80px;
  }
}

.custom-shape-divider-bottom-1607088732 .shape-fill {
  fill: $purple-dark;
}

.bg-phone {
  position: relative;
}

.custom-shape-divider-bottom-1607094095 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1607094095 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 240px;
  transform: rotateY(180deg);
  @media screen and (max-width:480px) {
    height: 80px;
  }
}

.custom-shape-divider-bottom-1607094095 .shape-fill {
  fill: #120024;
}

@media screen and (max-width: 768px) {
  .title-landing {
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 480px) {
  .title-landing {
    font-size: 2.5rem;
  }
}


.logo-pink {
  position: absolute;
  bottom: 5rem;
  left: 50%;
  transform: translate(-50%, 0);
}
