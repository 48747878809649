.container-tiles {
  display: flex;
  flex-flow: row wrap;
  transition: transform 300ms ease 100ms;
  @media screen and (max-width: 480px) {
    padding: 0 0;
  }
}

.card-tile {
  cursor: pointer;
  transition: transform 300ms ease 100ms;
  border-radius: 0.25rem;
  margin: 0 0.5rem;
  position: relative;
  width: 270px;
  height: 150px;
  transform-origin: 50% 50%;
  &:first-child {
    transform-origin: left center;
  }
  img {
    border-radius: 0.25rem;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .tile-content {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    position: relative;
  }
  .btn-more-info {
    font-size: 0.875rem;
  }
  &:hover {
    z-index: 1030;
    transform: scale(1.5) !important;
    transition-delay: 1s;
    box-shadow: 0px 20px 40px #120024;
    img {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .tile-content {
      position: absolute;
      width: 100%;
      .content {
        transform: scale(0.8);
        margin-left: -1rem;
      }
    }
  }
}

.modal-header-movie {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
  border: 0;
  position: relative;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  @media screen and (max-width: 992px) {
    height: 400px;
  }
  .close {
    background-color: rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(40px);
    border-radius: 50%;
    height: 65px;
    width: 65px;
    transition: 0.2s ease-in-out;
    color: #fff;
    text-shadow: none;
    margin-right: 1rem;
    margin-top: 1rem;
    opacity: 1;
    @media screen and (max-width: 992px) {
      height: 40px;
      width: 40px;
      padding: 0;
    }
    span {
      display: none;
    }
    &:after {
      content: '\f00d';
      font-family: 'Font Awesome 5 Pro';
      font-size: 1.5rem;
      font-weight: 300;
      color: #fff;
      @media screen and (max-width: 992px) {
        font-size: 1.5rem;
      }
    }
    &:focus {
      outline: 0;
    }
  }
  .gradient-tile {
    background-image: linear-gradient(transparent, $purple-900);
    height: 200px;
    &.gradient-black {
      background-image: linear-gradient(transparent, $black);
    }
    @media screen and (max-width: 992px) {
      height: 400px;
    }
  }
}

.modal-body {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.btn-play {
  border-radius: 50%;
  position: absolute;
  right: 0.875rem;
  top: -1.5rem;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.modal-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .fav-button,
  .button-trailer {
    @include background-blur;
  }
  @media screen and (max-width: 992px) {
    flex-wrap: wrap;
    justify-content: space-between;
    .fav-button {
      width: 18%;
      display: block;
    }
    .button-play {
      display: block;
      width: 100%;
    }
    .button-trailer {
      display: block;
      width: 78%;
      &.full {
        width: 100%;
      }
    }
  }
}

.rate-card {
  border-radius: 1rem;
  background-color: rgba($white, 0.08);
  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.continue-status-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
}

.purchase-episode-btn {
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 992px) {
    display: block;
    width: 100%;
    justify-content: center;
  }
  .button-play {
    width: 100%;
  }
}
