h1,
h2,
h3,
h4,
h5,
h6,
button,
.btn {
  font-family: $font-family-secondary;
}

.btn {
  font-size: 0.875rem;
}

a,
.btn-link {
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    box-shadow: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 2.3rem;
  font-weight: 500;
}

.display-4 {
  line-height: 5rem;
}

.btn-pass {
  position: absolute;
  right: 0.5rem;
  top: 0.75rem;
  color: rgba(255, 255, 255, 0.8);
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
  &:focus {
    box-shadow: none;
  }
}

.bg-gradient-primary {
  background: $bg-gradient-primary;
}

.bg-register {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('./../images/bg-register.png'), $bg-gradient-primary;
  background-position: left center;
}

.bg-register-reverse {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('./../images/bg-register-reverse.png'), $bg-gradient-primary;
  background-position: left center;
}

.btn-close-custom {
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 1080;
}

.modal-custom {
  .modal-content {
    background-color: #1e003b;
    padding: 2.5rem;
    @media (max-width: 480px) {
      padding: 1.5rem;
    }
    .btn-close {
      position: absolute;
      display: block;
      top: 0;
      right: 1rem;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.2);
      height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .btn-actions {
      @media (max-width: 480px) {
        font-size: 0.78rem;
      }
    }
  }
}

.modal-custom-details {
  .modal-content{
    background-color: transparent;
  }
}

.modal-custom-player {
  .modal-content {
    padding: 0 1rem;
    margin-top: 20rem;
    border-radius: 0.5rem;
  }
  &.modal-type-tvod .modal-content {
    background-color: #000000;
  }

  &.modal-type-adult .modal-content {
    background-color: #1e003b;
  }
}

.logo-nav {
  width: 66px;
}

.my-facebook-button-class {
  font-family: $font-family-secondary;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-size: 16px !important;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  background-color: #fff;
  border-radius: $border-radius;
  border: 1px solid #fff;
  padding: 1rem 1rem !important;
  font-weight: 500;
  &:focus {
    outline: 0;
  }
  .fa {
    margin-right: calc(0.34435vw + 3.38843px);
    color: $primary;
  }
  &:hover {
    opacity: 0.9;
  }
}
.my-google-button {
  font-family: $font-family-secondary;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-size: 16px !important;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  background-color: #fff;
  border-radius: $border-radius;
  border: 1px solid #fff;
  padding: 1rem 1rem !important;
  font-weight: 500;
  span {
    padding: 0 !important;
  }
  &:focus {
    outline: 0;
  }
}

.dropdown-menu-custom {
  width: 950px;
  transition: all 0.3s;
  background-color: #27004f;
  padding: 1rem;
  border-radius: 0.5rem !important;
  .dropdown-item,
  .dropdown-header {
    color: $white;
  }
  .dropdown-item {
    border-radius: $border-radius;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      background-color: $primary;
    }
  }
  @media screen and (max-width: 992px) {
    width: 100%;
    top: 3.5rem;
  }
}

.centered-axis-x {
  left: 50%;
  transform: translate(-50%, 0);
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.55em;
  vertical-align: -0.1em;
  font-family: 'Font Awesome 5 Pro';
  font-size: 1.2rem;
  font-weight: 300;
  content: '\f107';
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: none;
}

.dropdown-item-custom {
  color: #fff;
  &:hover {
    color: $purple;
  }
}

.background-line {
  position: relative;
  z-index: 1;
  &:before {
    border-top: 2px solid #dfdfdf;
    content: '';
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
  }
  span {
    background-color: #1e0044;
    padding: 0 15px;
  }
}

.btn-focus-0 {
  &:focus {
    box-shadow: none;
  }
}

.mt--2 {
  margin-top: -10rem;
}

.badge-purple-pink {
  color: #fff;
}

.text-shadow {
  text-shadow: 0 1px 4px #000000;
}

.p-body-2 {
  font-size: 1.125rem;
}

.container-categories {
  margin-top: -3.5rem;
  position: relative;
  z-index: 5;
}

.container-pt-custom {
  padding-top: 8rem;
  @media screen and (max-width: 480px) {
    padding-top: 5rem;
  }
}

.btn-explore {
  background-color: rgba($primary, 0.24);
  border: rgba($primary, 0.24);
}

.container-fluid {
  padding-left: 80px;
  padding-right: 80px;
  @media screen and (max-width: 992px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.text-helper {
  opacity: 0.5;
}

.pb-8 {
  padding-bottom: 9rem;
  @media screen and (max-width: 480px) {
    padding-bottom: 4rem;
  }
}

.logo-app-footer {
  height: 45px;
}

.landing-logo-app {
  .logo-app-footer {
    height: 75px;
    @media screen and (max-width: 768px) {
      height: 45px;
    }
  }
}

.dropdown-explore {
  margin-right: 2rem;
  @media screen and (max-width: 992px) {
    padding: 1rem 0;
    margin-right: 0;
  }
}

.collapse-custom {
  width: 90%;
}

.list-genre {
  column-count: 2;
  column-gap: 20px;
  list-style-type: none;
  padding: 0;
  li {
    list-style-position: inside;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

#player-container {
  width: 100vw;
  height: 100vh;
}

#player-trailer-container {
  width: 100vw;
  height: 100vh;
}

.spinner-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-modal {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: top;
  justify-content: center;
}

.spinner-fixed {
  top: 0;
  left: 0;
  width: 100%;
  background-color: $purple-dark;
  z-index: 100;
}

.btn-likes {
  border-radius: 50%;
  height: 48px;
  background-color: transparent;
  border: 0;
}

.title-detail {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  line-height: 3.5rem;
  @media screen and (max-width: 992px) {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
}

.bg-darker {
  background-color: rgba(0, 0, 0, 0.3);
}

.card-custom {
  border-radius: 1.5rem;
}

.btn-circle {
  border-radius: 50%;
  height: 36px;
  width: 36px;
  background-color: transparent !important;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: rgba($white, 0.5);
  }
  &:focus {
    box-shadow: none !important;
  }
  &.btn-circle-detail {
    height: 52px;
    width: 52px;
  }
  &.fav-button {
    &:active  {
      animation: grow 1s ease-in-out;
    }
    &.active {
      .fal {
        font-weight: 600 !important;
        color: $success !important;
      }
    }
  }
}

.btn-rating-unselected {
  color: #ffffff;
  opacity: 0.35;
}

.logo-app-recommend {
  height: 40px;
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(3);
  }
  100% {
    transform: scale(1);
  }
}

.btn-back {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 5;
  @media screen and (max-width: 480px) {
    font-size: 0.75rem;
    top: 0.2rem;
    left: 1rem;
    padding: 0.5rem;
  }
}

.btn-beginning {
  border-radius: 40px;
  height: 40px;
  position: absolute;
  top: 2rem;
  left: 5rem;
  @media screen and (max-width: 480px) {
    font-size: 0.75rem;
    top: 0.2rem;
    left: 4rem;
    padding: 0.5rem;
  }
  span{
    padding-right: 1rem
  }
}

.btn-next {
  position: absolute;
  bottom: 7rem;
  right: 2rem;
  border-radius: 40px;
  height: 40px;
  padding: 0.5rem;
  font-size: 1rem;
  @media screen and (max-width: 1200px) {
    right: 1rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 0.8rem;
    right: 0.5rem;
  }
  span{
    padding-left: 1rem
  }
}

.watermark {
  position: absolute;
  top: 2rem;
  right: 4.5rem;
  height: 2rem;
  width: 2.2rem;
  z-index: 5;
  @media screen and (max-width: 480px) {
    height: 1rem;
    width: 1.2rem;
    top: 0.2rem;
    right: 2rem;
  }
}

.title-name {
  position: absolute;
  bottom: 7rem;
  left: 2rem;
  color: #ffffff;
  font-size: 45px;
  @media screen and (max-width: 1200px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 992px) {
    font-size: 1.6rem;
    padding: 0.5rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.35rem;
    padding: 0.5rem;
  }
  @media screen and (max-width: 576px) {
    font-size: 1.2rem;
    padding: 0.5rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 1.1rem;
    padding: 0.5rem;
  }
}

.title-name-minimized {
  bottom: 6rem;
}

.skeleton-box-1 {
  width: 250px !important;
  flex-shrink: 0;
  @media screen and (max-width: 575px) {
    width: 220px !important;
  }
}

.skeleton-branded-container {
  display: flex;
  @media screen and (max-width:767px) {
  display: block;
  }
}

.skeleton-branded-banner {
  display: block;
  align-self: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  @media screen and (max-width:767px) {
    display: none;
  }
}

.skeleton-branded-container-info {
  display: block;
  align-self: center;
  padding-left: 2rem;
  margin: 1rem;
  @media screen and (max-width:767px) {
    padding: 1rem;
  }
}

.skeleton-branded-title {
  width: 50%;
  margin-bottom: 1.5rem;
  @media screen and (max-width:767px) {
    text-align: center;
    padding-top: 1rem;
    width: 100%;
  }
}

.skeleton-info-title {
  width: 12rem !important;
  @media screen and (max-width:767px) {
    width: 25rem !important;
  }
  @media screen and (max-width:480px) {
    width: 16rem !important;
  }
}

.skeleton-info-description {
  width: 10rem !important;
  @media screen and (max-width:767px) {
    width: 22rem !important;
  }
  @media screen and (max-width:480px) {
    width: 13rem !important;
  }
}

.skeleton-info-logo {
  width: 8rem !important;
  margin-right: 0.5rem;
  @media screen and (max-width:767px) {
    width: 6rem !important;
  }
}

.skeleton-info-button {
  width: 6rem !important;
  @media screen and (max-width:767px) {
    width: 9rem !important;
  }
}

.skeleton-box-branded {
  width: 15.5rem !important;
  height: 22rem !important;
  flex-shrink: 0;
  @media screen and (max-width: 767px) {
    width: 10rem !important;
    height: 16rem !important;
  }
}

.skeleton-branded-logo {
  display: block;
  align-items: center;
  @media screen and (max-width:767px) {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;
  }
}



.react-loading-skeleton {
  opacity: 0.2;
}

.episode-container {
  height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(white, 0.3);
    border-radius: 1rem;
  }
}

.btn-rounded {
  border-radius: 1rem;
  &:focus {
    box-shadow: $gold-500 !important;
  }

  &:hover {
    background-color: $gold-500 !important;
    box-shadow: 0px 0px 0px 4px rgba(252, 205, 80, 0.24);
  }
}

.buttons-container-tvod {
  display: flex;
  justify-content: center;
}

#ticket {
  h1 {
    font-size: 3rem;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 1.5rem !important;
  }

  .btn-content {
    background-color: $gold-500 !important;
    border: $gold-500 !important;
    border-radius: 32px;
    color: #000;
    font-size: 1rem;

    &:focus {
      box-shadow: $gold-500 !important;
    }

    &:hover {
      background-color: $gold-500 !important;
      box-shadow: 0px 0px 0px 4px rgba(252, 205, 80, 0.24);
    }
  }

  .btn-content-disabled {
    background: rgba(255, 255, 255, 0.16) !important;
    backdrop-filter: blur(28px) !important;
    color: #fff;
    border-radius: 32px;

    &:focus {
      box-shadow: $gold-500 !important;
    }

    &:hover {
      background: #FAF7FC !important;
      color: #0E021A !important;
      box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.24);
      border-radius: 32px;
    }
  }

  .btn-primary {
    box-shadow: transparent !important;
    border-color: transparent !important;

    &:focus {
      box-shadow: transparent !important;
      border-color: transparent !important;
    }
  }
}

#carousel-custom {
  .bg-slider::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(360deg, #120024 0%, rgba(18, 0, 36, 0.5) 53.13%, #120024 100%), url('../images/img-background.png');
    z-index: -1;

    filter: blur(5px);
    -moz-filter: blur(5px);
    -webkit-filter: blur(5px);
    -o-filter: blur(5px);

    transition: all 2s linear;
    -moz-transition: all 2s linear;
    -webkit-transition: all 2s linear;
    -o-transition: all 2s linear;
  }


  ol.carousel-indicators {
    margin-bottom: -3rem;
  }

  .carousel-caption {
    text-align: left !important;
  }

  .carousel-control-prev {
    left: -12%;
    @media screen and (max-width: 768px) {
      left: -15%;
    }
  }

  .carousel-control-next {
    right: -12%;
    @media screen and (max-width: 768px) {
      right: -15%;
    }
  }

  .carousel-control-prev-icon {
    background-image: url(../images/prev-icon.svg) !important;
  }

  .carousel-control-next-icon {
    background-image: url(../images/next-icon.svg) !important;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .carousel-caption {
    position: absolute;
    right: 3%;
    bottom: 0%;
    left: 3%;
  }

  h2,
  h6 {
    line-height: 120%;
    display: inline;
    margin-bottom: 0;
  }

  @media (max-width: 24rem) {
    h1 {
      font-size: 1em;
    }

    h2 {
      font-size: 1em;
    }

    .carousel-control-prev,
    .carousel-control-next {
      display: none;
    }

  }

  .btn-content,
  .btn-content-play {
    background-color: $gold-500 !important;
    border: $gold-500 !important;
    color: #000;


    &:focus {
      box-shadow: $gold-500 !important;
    }

    &:hover {
      background-color: #E1A604 !important;
      box-shadow: 0px 0px 0px 4px rgba(252, 205, 80, 0.24);
    }
  }

  .btn-content-play {
    transition: all 0.7s;
    opacity: 0;
  }

  .btn-content-large {
    background-color: $gold-500 !important;
    border: $gold-500 !important;
    color: #000;
    transition: transform 0.7s;

    &:hover {
      background-color: #E1A604 !important;
      box-shadow: 0px 0px 0px 4px rgba(252, 205, 80, 0.24) !important;
    }
  }

  .btn-play-gray {
    background: rgba(255, 255, 255, 0.16);
    border: rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(28px);
    transition: all 0.7s;
    opacity: 0;

    &:hover {
      background: #FAF7FC;
      color: #1e003b;
      box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.24);
    }
  }


  div.active.carousel-item:hover {
    .btn-content img {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(25deg) brightness(103%) contrast(101%);
    }

    .btn-content {
      background: rgba(255, 255, 255, 0.16) !important;
      backdrop-filter: blur(28px) !important;
      color: #fff;
    }

    .btn-content-large {
      transform: translateX(-50%);
      transition: all 0.7s;
    }

    .btn-play-gray,
    .btn-content-play {
      opacity: 1;
    }
  }

}

.btn-mute {
  background-color: rgba(0,0,0,0.1) !important;
  backdrop-filter: blur(40px);
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  transition: .2s ease-in-out;
  position: absolute;
  bottom: 3rem;
  right: 2.5rem;
  z-index: 2;
  @media screen and (max-width:992px) {
    height: 1.5rem;
    width: 1.5rem;
    padding: 0;
  }
}

.tickets-empty {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 439px;
  flex-direction: column;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(360deg, #120024 0%, rgba(18, 0, 36, 0.5) 53.13%, #120024 100%), url('../images/img-background.png');
    filter: blur(5px);
    z-index: -1;
  }

  h2 {
    text-align: center;
    max-width: 50%;
    margin-top: 3rem;
    @media screen and (max-width: 768px) {
      max-width: 70%;
    }
    @media screen and (max-width: 360px) {
      max-width: 100%;
    }
  }
  p {
    text-align: center;
    max-width: 70%;
  }
  button {
    margin-bottom: 10rem;
    img {
      margin-right: 0.2rem;
      width: 20px;
      height: 16px;
      padding-bottom: 0.2rem;
    }
  }

  @media screen and (max-width: 768px) {
    height: '457px';
  }
  @media screen and (max-width: 360px) {
    height: '357px';
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 0;
  width: 100%;
  z-index: 1;
}
@media (max-width: 900px) {
  .grid-container {
      grid-template-columns: repeat(2, 1fr);
  }
}
